import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  IconButton,
  Snackbar,
  Alert,
  Tooltip,
  useMediaQuery,
  useTheme,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  CircularProgress,
  Modal,
  Button
} from "@mui/material";
import {
  Search as SearchIcon,
  Info as InfoIcon,
  ArrowBack as ArrowBackIcon
} from "@mui/icons-material";
import axios from "axios";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { format } from 'date-fns';

const SuperuserCalendars = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [view, setView] = useState("dayGridMonth");
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openEventModal, setOpenEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    setLoading(true);
    try {
      const appointmentsResponse = await axios.get("https://api.cope.ke/appointments");
      const assessmentsResponse = await axios.get("https://api.cope.ke/sp-responses");
      const therapySessionsResponse = await axios.get("https://api.cope.ke/api/therapy-sessions");
      const usersResponse = await axios.get("https://api.cope.ke/users");
      const providersResponse = await axios.get("https://api.cope.ke/service_providers");
  
      const appointments = appointmentsResponse.data;
      const assessments = assessmentsResponse.data;
      const therapySessions = therapySessionsResponse.data;
      const users = usersResponse.data;
      const providers = providersResponse.data;
  
      console.log("Appointments:", appointments);
      console.log("Assessments:", assessments);
      console.log("Therapy Sessions:", therapySessions);
  
      // Map appointments to events
      const appointmentEvents = appointments.map((appointment) => {
        const user = users.find((user) => user.user_id === appointment.user_id);
        const provider = providers.find((provider) => provider.provider_id === appointment.provider_id);
  
        return {
          title: `${user?.name || "Unknown"} - ${appointment.status}`,
          start: new Date(appointment.appointment_date).toISOString(), // Correct ISO format
          extendedProps: {
            type: "Appointment",
            date: new Date(appointment.appointment_date).toLocaleDateString(),
            time: new Date(appointment.appointment_date).toLocaleTimeString(),
            status: appointment.status,
            userName: user?.name || "Unknown",
            userEmail: user?.email || "Unknown",
            userPhone: user?.phone || "Unknown",
            providerName: provider?.company_name || "Unknown",
          },
        };
      });
  
  
      // Map therapy sessions to events
      const therapySessionEvents = therapySessions.map((session) => ({
        title: `Therapy: ${session.plans[0] || "Unknown"}`,
        start: new Date(session.session_date).toISOString(), // Correct ISO format
        extendedProps: {
          type: "Therapy Session",
          topic: session.plans[0] || "No topic specified",
          date: new Date(session.session_date).toLocaleDateString(),
          time: session.time || "Not specified",
          therapist: session.provider_name || "Unknown",
        },
      }));
  
      const allEvents = [...appointmentEvents, ...therapySessionEvents];
  
      console.log("Mapped Events:", allEvents); // Debug mapped events
      setEvents(allEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
      setSnackbar({ open: true, message: "Failed to fetch events data", severity: "error" });
    } finally {
      setLoading(false);
    }
  };
  
  

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setEvents(
      events.filter(
        (event) =>
          event.title.toLowerCase().includes(value)
      )
    );
  };

  const handleViewChange = (event) => {
    setView(event.target.value);
  };

  const handleEventClick = (info) => {
    const { title, extendedProps } = info.event;
    setSelectedEvent({
      title,
      ...extendedProps
    });
    setOpenEventModal(true);
  };

  const handleInfoClick = () => {
    setOpenInfoModal(true);
  };

  const handleCloseInfoModal = () => {
    setOpenInfoModal(false);
  };

  const handleCloseEventModal = () => {
    setOpenEventModal(false);
    setSelectedEvent(null);
  };

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Calendars and Events</Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <Tooltip title="Back">
          <IconButton onClick={() => navigate('/admin-profile')}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="How to use">
          <IconButton onClick={handleInfoClick}>
            <InfoIcon color="primary" />
          </IconButton>
        </Tooltip>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search events"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <SearchIcon />
            ),
          }}
          sx={{ mr: 2, flexGrow: 1 }}
        />
        <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
          <InputLabel id="view-select-label">View</InputLabel>
          <Select
            labelId="view-select-label"
            id="view-select"
            value={view}
            onChange={handleViewChange}
            label="View"
          >
            <MenuItem value="dayGridMonth">Monthly</MenuItem>
            <MenuItem value="timeGridWeek">Weekly</MenuItem>
            <MenuItem value="listWeek">List</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
          initialView={view}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,listWeek'
          }}
          events={events}
          eventClick={handleEventClick}
          selectable={true}
          editable={true}
          
        />
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Modal
        open={openInfoModal}
        onClose={handleCloseInfoModal}
        aria-labelledby="info-modal-title"
        aria-describedby="info-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="info-modal-title" variant="h6" component="h2">
            How to Use the Appointments Screen
          </Typography>
          <Typography id="info-modal-description" sx={{ mt: 2 }}>
            <ul>
              <li>Use the search bar to filter events.</li>
              <li>Select a view from the dropdown (Monthly, Weekly, List) to change the calendar view.</li>
              <li>Click on an event to see more details.</li>
            </ul>
          </Typography>
          <Button onClick={handleCloseInfoModal} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
      <Modal
        open={openEventModal}
        onClose={handleCloseEventModal}
        aria-labelledby="event-modal-title"
        aria-describedby="event-modal-description"
      >
        <Box sx={modalStyle}>
          {selectedEvent && (
            <>
              <Typography id="event-modal-title" variant="h6" component="h2">
                {selectedEvent.title}
              </Typography>
              <Typography id="event-modal-description" sx={{ mt: 2 }}>
                <p><strong>Date:</strong> {selectedEvent.date}</p>
                <p><strong>Time:</strong> {selectedEvent.time}</p>
                <p><strong>Status:</strong> {selectedEvent.status}</p>
                <p><strong>Provider:</strong> {selectedEvent.providerName}</p>
                <p><strong>Email:</strong> {selectedEvent.userEmail}</p>
                <p><strong>Phone:</strong> {selectedEvent.userPhone}</p>
              </Typography>
              <Button onClick={handleCloseEventModal} sx={{ mt: 2 }}>
                Close
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserCalendars;
