import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  Button,
  Modal,
  CircularProgress,
  TextField,
  Snackbar,
  Alert,
  Chip,
  Avatar,
  Tooltip,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Search as SearchIcon, Edit as EditIcon, Delete as DeleteIcon, NoteAdd as NoteAddIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

const SuperuserTherapySessions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSession, setSelectedSession] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [notes, setNotes] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchSessions = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/api/therapy-sessions");
      setSessions(response.data);
      setFilteredSessions(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch sessions data", severity: "error" });
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredSessions(
      sessions.filter(
        (session) =>
          session.name.toLowerCase().includes(value) ||
          session.email.toLowerCase().includes(value) ||
          session.phone.toLowerCase().includes(value)
      )
    );
  };

  const handleEditIconClick = (session) => {
    setSelectedSession(session);
    setFormData(session);
    setOpenEditModal(true);
  };

  const handleDeleteIconClick = (session) => {
    setSelectedSession(session);
    setOpenDeleteModal(true);
  };

  const handleNotesIconClick = (session) => {
    setSelectedSession(session);
    setOpenNotesModal(true);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.put(`https://api.cope.ke/sessions/${selectedSession.id}`, formData);
      fetchSessions();
      setOpenEditModal(false);
      setSnackbar({ open: true, message: "Session updated successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to update session data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      await axios.delete(`https://api.cope.ke/sessions/${selectedSession.id}`);
      fetchSessions();
      setOpenDeleteModal(false);
      setSnackbar({ open: true, message: "Session deleted successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to delete session data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleNotesSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post(`https://api.cope.ke/sessions/${selectedSession.id}/notes`, { notes });
      fetchSessions();
      setOpenNotesModal(false);
      setSnackbar({ open: true, message: "Notes added successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to add notes", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const renderCards = () => (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {filteredSessions.map((session) => (
        <Card key={session.id} sx={{ width: 300, m: 2 }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Avatar src={session.avatar || ''}>
                {!session.avatar && <FontAwesomeIcon icon={faUser} size="2x" />}
              </Avatar>
              <Box>
                <Tooltip title="Edit">
                  <IconButton onClick={() => handleEditIconClick(session)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDeleteIconClick(session)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add Notes">
                  <IconButton onClick={() => handleNotesIconClick(session)}>
                    <NoteAddIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Typography variant="h6">{session.name}</Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faEnvelope} /> {session.user_email}
            </Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faPhone} /> {session.phone}
            </Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faClipboardList} /> {session.notes}
            </Typography>
            <Typography variant="body2">
              Status:{" "}
              {session.status === "inactive" ? (
                <Chip label="Inactive" color="error" />
              ) : (
                <Chip label="Active" color="success" />
              )}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Therapy Sessions</Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search sessions"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <SearchIcon />
            ),
          }}
          sx={{ mr: 2 }}
        />
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredSessions.length > 0 ? (
        renderCards()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No sessions found.
        </Typography>
      )}

      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleEditSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Edit Session</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={formData.name || ""}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            value={formData.email || ""}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="status"
            label="Status"
            name="status"
            value={formData.status || ""}
            onChange={(e) => setFormData({ ...formData, status: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="notes"
            label="Notes"
            name="notes"
            value={formData.notes || ""}
            onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openNotesModal} onClose={() => setOpenNotesModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleNotesSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Add Notes</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="notes"
            label="Notes"
            name="notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} sx={{ overflowY: "auto" }}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>Confirm Delete</Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this session record?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            sx={{ mt: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Delete"}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setOpenDeleteModal(false)}
            sx={{ mt: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserTherapySessions;
