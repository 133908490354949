import React, { useState, useEffect,useContext } from "react";
import {
  Container,
  Typography,
  Box,
  IconButton,
  Breadcrumbs,
  Link,
  Snackbar,
  Alert,
  useMediaQuery,
  Badge
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faTasks,
  faDollarSign,
  faUsers,
  faComments,
  faUserMd,
  faCartPlus,
  faBlog,
  faMapMarkerAlt,
  faEnvelopeOpenText,
  faFileAlt,
  faUserCircle,
  faArrowLeft,
  faSignOutAlt,
  faCog,
  faRecycle
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Component Imports (Assumed to be available)
import SuperuserAppointments from "../components/SuperuserAppointments";
import SuperuserTherapyTreatment from "../components/SuperuserTherapyTreatment";
import SuperuserTherapyAssessments from "../components/SuperuserTherapyAssessments";
import SuperuserUserAccounts from "../components/SuperuserUserAccounts";
import SuperuserTickets from "../components/SuperuserTickets";
import SuperuserSettings from "../components/SuperuserSettings";
import SuperuserResourceCenters from "../components/SuperuserResourceCenters";
import SuperuserBlogPosts from "../components/SuperuserBlogPosts";
import SuperuserCalendars from "../components/SuperuserCalendars";
import SuperuserSubscriptions from "../components/SuperuserSubscriptions";
import SuperuserBilling from "../components/SuperuserBilling";
import SuperuserClients from "../components/SuperuserClients";
import SuperuserMap from "./SuperuserMap";
import SuperuserTherapyPostRecovery from "./SuperuserTherapyPostRecovery";
import SuperuserQuestionnaires from "./SuperuserQuestionnaires"
import SuperuserTherapySessions from "./SuperuserTherapySessions";

import { UserContext } from "../UserContext";

import logo from '../assets/secondary_logo.png'; // Replace with your actual logo image path


const AdminInbox = () => {
  const navigate = useNavigate();
  const { user, logout } = useContext(UserContext);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [mainMenu, setMainMenu] = useState(true); // Track if we are on the main menu
  const [subMenu, setSubMenu] = useState(null); // Track if a sub-menu is selected
  const [breadcrumbTitle, setBreadcrumbTitle] = useState("Home");
  const [badgeCounts, setBadgeCounts] = useState({});

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Media query to detect mobile view
  const isMobile = useMediaQuery("(max-width:600px)");

  // Fetch badge counts when the sub-menu loads
  useEffect(() => {
  const fetchBadgeCounts = async () => {
    const components = ["Appointments", "SelfAssessments", "Treatments", "PostRecovery", "Questionnaire", "Billing", "Subscriptions", "ResourceCenters", "Maps", "Ticketing", "Blog","Clients", "UserAccounts"];
    const counts = {};

    for (const component of components) {
      counts[component] = await getBadgeCount(component); // Fetch badge count for each component
    }

    setBadgeCounts(counts); // Update state with fetched counts
  };

  if (subMenu) fetchBadgeCounts();
}, [subMenu]); // Refetch counts whenever the sub-menu changes


const getBadgeCount = async (component) => {
  const endpoints = {
    Appointments: "https://api.cope.ke/appointments",
    SelfAssessments: "https://api.cope.ke/sp-responses",
    Treatments: "https://api.cope.ke/treatment",
    PostRecovery: "https://api.cope.ke/post_recoveries",
    Questionnaire: "https://api.cope.ke/questions",
    Billing: "https://api.cope.ke/billing",
    Subscriptions: "https://api.cope.ke/subscriptions",
    ResourceCenters: "https://api.cope.ke/api/resource-centres",
    Maps: "https://api.cope.ke/maps",
    Ticketing: "https://api.cope.ke/tickets",
    Blog: "https://api.cope.ke/blogposts",
    Clients: "https://api.cope.ke/service_providers",
    UserAccounts: "https://api.cope.ke/api/accounts",
  };

  const url = endpoints[component];

  if (!url) return 0; // Return 0 if component does not match

  try {
    const response = await axios.get(url);

    // Assuming the response contains an array of pending items
    return response.data.length;
  } catch (error) {
    console.error(`Error fetching badge count for ${component}:`, error);
    return 0; // Return 0 on error
  }
};

  // Define icons for the main menu
  const mainMenuIcons = [
    { name: "Therapy Services", icon: faUserMd, subMenu: "therapy" },
    { name: "Financials", icon: faDollarSign, subMenu: "financials" },
    { name: "Wellness Hub", icon: faUserCircle, subMenu: "administration" },
    { name: "Calendar", icon: faCalendarAlt, component: "Calendar" },
    { name: "Settings", icon: faCog, component: "Settings" },
  ];

  // Define sub-menu icons for Therapy
  const therapySubMenuIcons = [
    { name: "Appointments", icon: faCalendarAlt, component: "Appointments" },
    { name: "Assessments", icon: faComments, component: "SelfAssessments" },
    { name: "Sessions", icon: faUserMd, component: "Sessions" },
    { name: "Post Recovery", icon: faRecycle, component: "PostRecovery" },
    { name: "Questionnaire ", icon: faFileAlt, component: "Questionnaire" },
    
  ];

  // Define sub-menu icons for Financials
  const financialSubMenuIcons = [
    { name: "Billing", icon: faDollarSign, component: "Billing" },
    { name: "Subscriptions", icon: faCartPlus, component: "Subscriptions" },
    { name: "Resource Centers", icon: faMapMarkerAlt, component: "ResourceCenters" },
    { name: "Maps", icon: faMapMarkerAlt, component: "Map" },
  ];

  // Define sub-menu icons for Administration
  const adminSubMenuIcons = [
    { name: "Ticketing", icon: faEnvelopeOpenText, component: "Tickets" },
    { name: "Blog", icon: faBlog, component: "Blog" },
    
    { name: "Clients", icon: faUsers, component: "Clients" },
    { name: "User Accounts", icon: faUserCircle, component: "UserAccounts" },
    
  ];

  // Handle navigation back to main menu
  const handleBackToMainMenu = () => {
    if (selectedComponent) {
      setSelectedComponent(null); // Go back to the sub-menu
    } else if (subMenu) {
      setSubMenu(null); // Go back to the main menu
      setMainMenu(true);
      setBreadcrumbTitle("Home");
    }
  };

  // Handle click on main menu or sub-menu icons
  const handleMainMenuClick = (menu) => {
    if (menu === "Logout") {
      handleLogout();
    } else if (menu === "therapy" || menu === "financials" || menu === "administration") {
      setSubMenu(menu);
      setMainMenu(false);
      setBreadcrumbTitle(menu.charAt(0).toUpperCase() + menu.slice(1)); // Set breadcrumb title dynamically
    } else {
      setSelectedComponent(menu);
      setMainMenu(false);
      setBreadcrumbTitle(menu); // Set the breadcrumb to component name
    }
  };

  // Render icons based on whether we are in main menu or sub-menu
  const renderIcons = (icons) => (
    <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 4 }}>
      {icons.map((icon, index) => (
        <Box key={index} sx={{ textAlign: "center" }}>
          <IconButton
            onClick={() =>
              icon.subMenu
                ? handleMainMenuClick(icon.subMenu)
                : setSelectedComponent(icon.component)
            }
            sx={{
              fontSize: 40,
              color: "primary.main",
              transition: "transform 0.3s",
              "&:hover": { transform: "scale(1.2)", color: "secondary.main" },
            }}
          >
            <FontAwesomeIcon icon={icon.icon} />
          </IconButton>
          <Typography variant="body2" sx={{fontWeight:'bold'}}>{icon.name}</Typography>
        </Box>
      ))}
    </Box>
  );

  // Render the selected component
  const renderComponent = () => {
    switch (selectedComponent) {
      case "Appointments":
        return <SuperuserAppointments />;
      case "SelfAssessments":
        return <SuperuserTherapyAssessments />;
      case "Billing":
        return <SuperuserBilling />;
      case "PostRecovery":
        return <SuperuserTherapyPostRecovery />;
      case "Sessions":
        return <SuperuserTherapySessions />;
      case "Questionnaire":
        return <SuperuserQuestionnaires />;
      case "ResourceCenters":
        return <SuperuserResourceCenters />;
      case "Map":
        return <SuperuserMap />;
      case "Tickets":
        return <SuperuserTickets />;
      case "Blog":
        return <SuperuserBlogPosts />;
      case "Subscriptions":
        return <SuperuserSubscriptions />;
      case "Clients":
        return <SuperuserClients />;
      case "UserAccounts":
        return <SuperuserUserAccounts />;
      case "Calendar":
        return <SuperuserCalendars />;
      case "Settings":
        return <SuperuserSettings />;
      default:
        return mainMenu ? renderIcons(mainMenuIcons) : null;
    }
  };

  const handleLogout = () => {
    logout(); 
    navigate("/admin_login");
    setSnackbar({ open: true, message: 'Logged out successfully', severity: 'success' });
  };

  const dynamicSubMenu = (menu, selectedComponent) => {
    const linkStyle = {
      cursor: "pointer",
      textDecoration: "none",
      color: "primary.main",
      fontWeight: "bold",
      padding: "8px 16px",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      gap: "10px",
      transition: "background-color 0.3s, color 0.3s",
      "&:hover": {
        backgroundColor: "#F9A64F",
        color: "#fff",
      },
    };
  
    const getFilteredIcons = (icons) =>
      icons.filter((icon) => icon.component !== selectedComponent);
  
    const renderMenuItems = (icons) =>
      icons.map((icon) => (
        <Box
          key={icon.name}
          onClick={() => setSelectedComponent(icon.component)}
          sx={{
            ...linkStyle,
            border: "1px solid teal",
            padding: "12px",
          }}
        >
          <FontAwesomeIcon icon={icon.icon} />
          <Typography>{icon.name}</Typography>
          <Badge
            badgeContent={badgeCounts[icon.component] || 0} // Use fetched badge count
            color="secondary"
            sx={{ ml: "auto" }}
          />
        </Box>
      ));
  
    switch (menu) {
      case "therapy":
        return renderMenuItems(getFilteredIcons(therapySubMenuIcons));
      case "financials":
        return renderMenuItems(getFilteredIcons(financialSubMenuIcons));
      case "administration":
        return renderMenuItems(getFilteredIcons(adminSubMenuIcons));
      default:
        return null;
    }
  };
  
  
  

  return (
    <Container sx={{ mt: 8, mb: 4 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2, pt:2 }}>
      {!mainMenu && ( // Conditionally render back button
          <IconButton onClick={handleBackToMainMenu} sx={{ color: "teal" }}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconButton>
        )}
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: "#2C1C18" }}>
  <Link
    underline="hover"
    color="inherit"
    onClick={() => {
      setSelectedComponent(null);
      setSubMenu(null);
      setMainMenu(true);
      setBreadcrumbTitle("Home");
    }}
    style={{ cursor: "pointer" }}
  >
    Home
  </Link>
  {subMenu && (
    <Link
      underline="hover"
      color="inherit"
      onClick={() => {
        setSelectedComponent(null); // Clear selected component
        setMainMenu(false); // Stay in sub-menu
        setBreadcrumbTitle(subMenu.charAt(0).toUpperCase() + subMenu.slice(1));
      }}
      style={{ cursor: "pointer" }}
    >
      {breadcrumbTitle}
    </Link>
  )}
  {selectedComponent && (
    <Typography color="text.primary">{selectedComponent}</Typography>
  )}
</Breadcrumbs>

      <Box sx={{ ml: 2, display: isMobile ? 'none' : 'flex', alignItems: 'center', gap: 2, }}>{subMenu && dynamicSubMenu(subMenu)}</Box>
      </Box>

      {/* Render Main Menu or Sub Menu */}
      {mainMenu && !selectedComponent && renderIcons(mainMenuIcons)}

      {/* Sub Menu Rendering */}
      {!mainMenu && !selectedComponent && subMenu === "therapy" && (
        <>
          {renderIcons(therapySubMenuIcons)}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={logo} alt="Cope Logo" style={{ height: '50px', marginRight: '10px' }} />
            </Box>
          </Box>
        </>
      )}
      {!mainMenu && !selectedComponent && subMenu === "financials" && (
        <>
          {renderIcons(financialSubMenuIcons)}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </>
      )}
      {!mainMenu && !selectedComponent && subMenu === "administration" && (
        <>
          {renderIcons(adminSubMenuIcons)}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </>
      )}

      {/* Render Selected Component */}
      {selectedComponent && (
        <Box sx={{ mt: 4, mb: 4 }}>
          {renderComponent()}
          <Box sx={{ textAlign: "center", my: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </Box>
      )}

      {/* Centered Logout Button */}
      {mainMenu && (
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <IconButton
            onClick={handleLogout}
            sx={{
              fontSize: 40,
              color: "primary.main",
              transition: "transform 0.3s",
              "&:hover": { transform: "scale(1.2)", color: "secondary.main" },
            }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
          </IconButton>
          <Typography color="#F9A64F" variant="body2" sx={{fontWeight:"bold"}}>Logout</Typography>
        </Box>
      )}

      {/* Snackbar for feedback messages */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ open: false })}>
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminInbox;
