import React, { useState, useRef, useEffect, useContext } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Box,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  Modal,
  Snackbar,
  Alert,
  TextField,
  CircularProgress,
  Link,
  Fab,
  Checkbox,
  FormControlLabel,
  Container
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/secondary_logo.png'; // Replace with your actual logo image path
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../UserContext'; // Importing UserContext

// Import PropTypes for prop validation
import PropTypes from 'prop-types';

const Navbar = ({setPage}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, user } = useContext(UserContext); // Accessing context

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openPatientModal, setOpenPatientModal] = useState(false);
  const [openTherapistModal, setOpenTherapistModal] = useState(false);
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [openForgotPasswordTherapistModal, setOpenForgotPasswordTherapistModal] = useState(false);
  const [openPatientSignupModal, setOpenPatientSignupModal] = useState(false);
  const [openTherapistSignupModal, setOpenTherapistSignupModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [error, setError] = useState('');

  // State to track the active menu item
  const [activeItem, setActiveItem] = useState('Home'); // Default to 'Home'

 // Show snackbar function with customized severity
 const showSnackbar = (message, severity = 'success') => {
  setSnackbar({ open: true, message, severity });
};

// Set active menu item based on URL
useEffect(() => {
  const path = location.pathname.slice(1) || 'Home';
  setActiveItem(path.charAt(0).toUpperCase() + path.slice(1)); // Capitalize first letter
  window.scrollTo(0, 0); // Scroll to the top on page change
}, [location]);

const handleMenuItemClick = (itemText, path) => {
  setActiveItem(itemText);
  navigate(path); // Navigate without reloading
};

const menuItems = [
  { text: 'Home', path: '/' },
  { text: 'Blog', path: '/blog' },
  { text: 'Corporates', path: '/corporates' },
  { text: 'Contacts', path: '/contacts' },
];

 // Close snackbar
 const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    phone:'254'
  });

   // Handle phone number change
   const handlePhoneChange = (e) => {
    let input = e.target.value;

    // Remove any non-numeric characters
    input = input.replace(/\D/g, "");

    // Remove any existing '254' prefix to avoid duplication, then re-add it
    if (input.startsWith("254")) {
        input = input.slice(3);
    } else if (input.startsWith("0")) {
        input = input.slice(1); // Remove initial '0' if present
    }

    // Limit the input to 9 digits after the '254' prefix
    input = input.slice(0, 9);

    // Set the phone number with '254' prefixed
    setFormData((prev) => ({ ...prev, phone: "254" + input }));
};

// Function to validate email using regex
// Validate email format
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

// Handle email change with validation
const handleEmailChange = (e) => {
  const email = e.target.value;
  setFormData((prev) => ({ ...prev, email }));

  if (!validateEmail(email)) {
    setError('Invalid email format');
  } else {
    setError('');
  }
};


  // Refs for the email input fields
  const patientEmailRef = useRef(null);
  const therapistEmailRef = useRef(null);
  const forgotPasswordEmailRef = useRef(null);
  const forgotPasswordTherapistEmailRef = useRef(null);

  // Toggle drawer on mobile view
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  // Focus the email input field when respective modal is opened
  useEffect(() => {
    if (openPatientModal && patientEmailRef.current) {
      patientEmailRef.current.focus();
    }
  }, [openPatientModal]);

  useEffect(() => {
    if (openTherapistModal && therapistEmailRef.current) {
      therapistEmailRef.current.focus();
    }
  }, [openTherapistModal]);

  useEffect(() => {
    if (openForgotPasswordModal && forgotPasswordEmailRef.current) {
      forgotPasswordEmailRef.current.focus();
    }
  }, [openForgotPasswordModal]);

  useEffect(() => {
    if (openForgotPasswordTherapistModal && forgotPasswordTherapistEmailRef.current) {
      forgotPasswordTherapistEmailRef.current.focus();
    }
  }, [openForgotPasswordTherapistModal]);

  // Open and close modals for Patients, Therapists, Forgot Passwords
  const handleOpenPatientModal = () => {
    setOpenTherapistModal(false);
    setOpenForgotPasswordModal(false);
    setOpenForgotPasswordTherapistModal(false);
    setOpenPatientModal(true);
  };

  const handleClosePatientModal = () => setOpenPatientModal(false);

  const handleOpenTherapistModal = () => {
    setOpenPatientModal(false);
    setOpenForgotPasswordModal(false);
    setOpenForgotPasswordTherapistModal(false);
    setOpenTherapistModal(true);
  };

  const handleCloseTherapistModal = () => setOpenTherapistModal(false);

  const handleOpenForgotPasswordModal = () => {
    setOpenPatientModal(false);
    setOpenTherapistModal(false);
    setOpenForgotPasswordTherapistModal(false);
    setOpenForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => setOpenForgotPasswordModal(false);

  const handleOpenForgotPasswordTherapistModal = () => {
    setOpenPatientModal(false);
    setOpenTherapistModal(false);
    setOpenForgotPasswordModal(false);
    setOpenForgotPasswordTherapistModal(true);
  };

  const handleCloseForgotPasswordTherapistModal = () => setOpenForgotPasswordTherapistModal(false);

  const handleOpenPatientSignupModal = () => {
    closeAllModals();
    setOpenPatientSignupModal(true);
  };

  const handleClosePatientSignupModal = () => setOpenPatientSignupModal(false);

  const handleOpenTherapistSignupModal = () => {
    closeAllModals();
    setOpenTherapistSignupModal(true);
  };

  const handleCloseTherapistSignupModal = () => setOpenTherapistSignupModal(false);

  const closeAllModals = () => {
    setOpenPatientModal(false);
    setOpenTherapistModal(false);
    setOpenForgotPasswordModal(false);
    setOpenForgotPasswordTherapistModal(false);
    setOpenPatientSignupModal(false);
    setOpenTherapistSignupModal(false);
  };

  const handleSubmitSignup = async (event, type) => {
    event.preventDefault();
    setLoading(true);
  
    const formData = new FormData(event.target);
  
    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      phone: formData.get('phone'),
      practice: type === 'Therapist' ? formData.get('practice') : undefined
    };
  
    const url =
      type === 'Therapist'
        ? 'https://api.cope.ke/service_providers'
        : 'https://api.cope.ke/users';
  
    try {
      await axios.post(url, data);
      showSnackbar(`${type} signed up successfully!`, 'success');
      closeAllModals();
    } catch (error) {
      // Check if the error response has a status of 409
      if (error.response && error.response.status === 409) {
        showSnackbar("User already exists. Please Log In", 'error');
      } else {
        showSnackbar(`Error signing up ${type}: ${error.message}`, 'error');
      }
    } finally {
      setLoading(false);
    }
  };
  

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value }); // Update state with field value
  };


  // Handles Patient Sign-in
  const handleSignInWithEmailPatient = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post("https://api.cope.ke/api/auth", {
        email: formData.email,
        password: formData.password,
      });
      if (response.status === 200) {
        // Handle successful login for patients (e.g., store token, redirect user, etc.)
        login(response.data);  // Set user data in context
        navigate("/user-profile"); // Redirect to patient profile
        showSnackbar("Sign-in successful", 'success');
      }
    } catch (error) {
      showSnackbar("Patient sign-in failed. Please check your email and password.", 'error');
    } finally {
      setLoading(false);
    }
  };

  // Handles Therapist Sign-in
  const handleSignInWithEmailTherapist = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post("https://api.cope.ke/api/auth", {
        email: formData.email,
        password: formData.password,
        therapist: true  // Assuming admin flag identifies therapists
      });
      if (response.status === 200) {
        // Handle successful login for therapists (e.g., store token, redirect user, etc.)
        login(response.data);  // Set therapist data in context
        navigate("/service-dashboard"); // Redirect to therapist profile
        showSnackbar("Sign-in successful", 'success');
      }
    } catch (error) {
      showSnackbar("Therapist sign-in failed. Please check your email and password.", 'error');
    } finally {
      setLoading(false);
    }
  };

  // Handles Patient Forgot Password
  const handlePatientForgotPassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('https://api.code.ke/auth/patient-forgot-password', {
        email: forgotPasswordEmail
      });
      if (response.status === 200) {
        showSnackbar('Password reset link sent to your email.');
      } else {
        showSnackbar('Failed to send password reset link. Please try again.');
      }
    } catch (error) {
      console.error('Error sending password reset email:', error);
      showSnackbar('Error sending password reset email. Please try again.');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  // Handles Therapist Forgot Password
  const handleTherapistForgotPassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('https://api.cope.ke/auth/sp-forgot-password', {
        email: forgotPasswordEmail
      });
      if (response.status === 200) {
        showSnackbar('Password reset link sent to your email.');
      } else {
        showSnackbar('Failed to send password reset link. Please try again.');
      }
    } catch (error) {
      console.error('Error sending password reset email:', error);
      showSnackbar('Error sending password reset email. Please try again.');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };
  

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };


  const handleOpenTermsModal = () => setOpenTermsModal(true);
  const handleCloseTermsModal = () => setOpenTermsModal(false);

  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: '#1a6051', padding: '10px 0' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Logo */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="Cope Logo" style={{ height: '50px', marginRight: '10px' }} />
          </Box>

          {/* Menu items for desktop */}
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {menuItems.map((item, index) => (
              <Button
              key={index}
              color="inherit"
              variant="text"
              onClick={() => handleMenuItemClick(item.text, item.path)}
              sx={{
                margin: '0 10px',
                backgroundColor: activeItem === item.text ? '#00695C' : 'transparent',
                textDecoration: activeItem === item.text ? 'underline' : 'none',
                textDecorationColor: activeItem === item.text ? '#F9A64F' : 'transparent',
                textUnderlineOffset: activeItem === item.text ? '4px' : 'none',
                color: activeItem === item.text ? '#fff' : '#ffffff',
                '&:hover': {
                  backgroundColor: '#F9A64F',
                  color: '#fff',
                },
              }}
            >
              {item.text}
            </Button>
            ))}
          </Box>

          {/* For Patients & For Therapists buttons */}
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#e46e24',
                marginRight: '10px',
                '&:hover': {
                  backgroundColor: '#fdbf57',
                  color: '#2a1a1a',
                },
              }}
              onClick={handleOpenPatientModal}
            >
              For Patients
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#2a1a1a',
                '&:hover': {
                  backgroundColor: '#fdbf57',
                  color: '#2a1a1a',
                },
              }}
              onClick={handleOpenTherapistModal}
            >
              For Therapists
            </Button>
          </Box>

          {/* Hamburger icon for mobile */}
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ ml: 'auto', position:'sticky' }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Swipeable Drawer for mobile */}
      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: '#1a6051', // Green background color for the drawer
            color: '#fff', // White text color
          },
        }}
      >
        <Box sx={{ width: 250 }}>
          <List>
            {/* Logo inside drawer */}
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
              <img src={logo} alt="Cope Logo" style={{ height: '50px' }} />
            </Box>

            {menuItems.map((item, index) => (
              <ListItem
              button
              key={index}
              component="a"
              href={item.href}
              onClick={() => handleMenuItemClick(item.text, item.path)}
              sx={{
                backgroundColor: activeItem === item.text ? '#00695C' : 'transparent', // Same background logic
                '&:hover': {
                  backgroundColor: '#F9A64F', // Hover effect
                },
              }}
            >
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  style: {
                    color: activeItem === item.text ? '#fff' : '#ffffff', // Change color based on active state
                    textDecoration: activeItem === item.text ? 'underline' : 'none', // Add underline for active item
                    textDecorationColor: activeItem === item.text ? '#F9A64F' : 'transparent', // Custom underline color
                    textUnderlineOffset: activeItem === item.text ? '4px' : 'none', // Same underline offset
                    fontWeight: 'bold',
                  },
                }}
              />
            </ListItem>
            ))}
            {/* For Patients button inside drawer */}
            <ListItem button onClick={handleOpenPatientModal}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#e46e24',
                  marginRight: '10px',
                  '&:hover': {
                    backgroundColor: '#fdbf57',
                    color: '#2a1a1a',
                  },
                  width: '100%',
                }}
              >
                For Patients
              </Button>
            </ListItem>
            {/* For Therapists button inside drawer */}
            <ListItem button onClick={handleOpenTherapistModal}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#2a1a1a',
                  '&:hover': {
                    backgroundColor: '#fdbf57',
                    color: '#2a1a1a',
                  },
                  width: '100%',
                }}
              >
                For Therapists
              </Button>
            </ListItem>
          </List>
        </Box>
      </SwipeableDrawer>

      {/* Patient Sign-in Modal */}
      <Modal open={openPatientModal} onClose={handleClosePatientModal}>
        <Box sx={modalStyle}>
          <Typography color='#00695C' variant="h6" component="h2" align="center" gutterBottom>
            Patient Sign-in
          </Typography>
          <form onSubmit = {handleSignInWithEmailPatient}>
            <TextField
              label="Email"
              name='email'
              id='email'
              placeholder="Enter your email"
              autoComplete="email"
              autoFocus
              fullWidth
              value={formData.email} // Bind to formData state 
              onChange={handleInputChange} // Handle input change
              inputRef={patientEmailRef}  // Set focus on this input when modal opens
              sx={{ marginBottom: '20px' }}
              required
              InputLabelProps={{ style: { color: '#2C1C18' } }}
              type='email'
              error={!!error && error.includes('email')}
              helperText={error.includes('email') ? error : ''}
            />
            <TextField
              label="Password"
              name="password"              
              placeholder="Enter your password"
              type="password"
              fullWidth
              value={formData.password} // Bind to formData state
              onChange={handleInputChange} // Handle input change
              sx={{ marginBottom: '20px' }}
              required
              InputLabelProps={{ style: { color: '#2C1C18' } }}
            />
            
            <Button
              variant="contained"
              fullWidth
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
            </Button>
            <Link
              href="#"
              variant="body2"
              onClick={handleOpenForgotPasswordModal}
              sx={{ display: 'block', textAlign: 'center', marginTop: '10px' }}
            >
              Forgot Password?
            </Link>
            <Link
              href="#"
              variant="body2"
              onClick={handleOpenPatientSignupModal}
              sx={{ display: 'block', textAlign: 'center', marginTop: '10px' }}
            >
              Don't have an account? Sign Up Here
            </Link>
          </form>
        </Box>
      </Modal>

      {/* Therapist Sign-in Modal */}
      <Modal open={openTherapistModal} onClose={handleCloseTherapistModal}>
        <Box sx={modalStyle}>
          <Typography color='#00695C' variant="h6" component="h2" align="center" gutterBottom>
            Therapist Sign-in
          </Typography>
          <form onSubmit={handleSignInWithEmailTherapist}>
            <TextField
              label="Email"
              id="email"
              name="email"
              autoComplete='email'
              autoFocus
              placeholder="Enter your email"
              fullWidth
              value={formData.email} // Bind to formData state
              onChange={handleInputChange} // Handle input change
              inputRef={therapistEmailRef}  // Set focus on this input when modal opens
              sx={{ marginBottom: '20px' }}
              required
              InputLabelProps={{ style: { color: '#2C1C18' } }}
            />
            <TextField
              label="Password"
              name="password"
              placeholder="Enter your password"
              type="password"
              fullWidth
              value={formData.password} // Bind to formData state
              onChange={handleInputChange} // Handle input change
              sx={{ marginBottom: '20px' }}
              required
              InputLabelProps={{ style: { color: '#2C1C18' } }}
            />
            <Button
              variant="contained"
              fullWidth
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
            </Button>
            <Link
              href="#"
              variant="body2"
              onClick={handleOpenForgotPasswordTherapistModal}
              sx={{ display: 'block', textAlign: 'center', marginTop: '10px' }}
            >
              Forgot Password?
            </Link>
            <Link
              href="#"
              variant="body2"
              onClick={handleOpenTherapistSignupModal}
              sx={{ display: 'block', textAlign: 'center', marginTop: '10px' }}
            >
              Don't have an account? Sign Up Here
            </Link>
          </form>
        </Box>
      </Modal>

      {/* Forgot Password Modal for Patients */}
      <Modal open={openForgotPasswordModal} onClose={handleCloseForgotPasswordModal}>
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" align="center" gutterBottom>
            Forgot Password
          </Typography>
          <form onSubmit={handlePatientForgotPassword}>
            <TextField
              label="Email"
              type='email'
              name='email'
              placeholder="Enter your email to reset password"
              fullWidth
              inputRef={forgotPasswordEmailRef}  // Set focus on this input when modal opens
              sx={{ marginBottom: '20px' }}
              required
              InputLabelProps={{ style: { color: '#2C1C18' } }}
              onChange={handleEmailChange}
              error={!!error && error.includes('email')}
              helperText={error.includes('email') ? error : ''}
            />
            <Button
              variant="contained"
              fullWidth
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
            </Button>
            {/* Back Button to return to Sign-in with FontAwesome Icon */}
            <Fab
              color="primary"
              size="small"
              onClick={handleOpenPatientModal}
              sx={{
                mt: 2,
                backgroundColor: '#00695C',
                '&:hover': {
                  backgroundColor: '#2C1C18',
                },
                mx: 'auto', // Center the FAB horizontally
                display: 'block',
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </Fab>
          </form>
        </Box>
      </Modal>

      {/* Forgot Password Modal for Therapists */}
      <Modal open={openForgotPasswordTherapistModal} onClose={handleCloseForgotPasswordTherapistModal}>
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" align="center" gutterBottom>
            Forgot Password for Therapists
          </Typography>
          <form onSubmit={handleTherapistForgotPassword}>
            <TextField
              label="Email"
              placeholder="Enter your email to reset password"
              fullWidth
              inputRef={forgotPasswordTherapistEmailRef}  // Set focus on this input when modal opens
              sx={{ marginBottom: '20px' }}
              required
              error={!!error && error.includes('email')}
              helperText={error.includes('email') ? error : ''}
              onChange={handleEmailChange}
            />
            <Button
              variant="contained"
              fullWidth
              type="submit"
              sx={{
                backgroundColor: '#F9A64F',
                '&:hover': {
                  backgroundColor: '#2C1C18',
                },
              }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
            </Button>
            {/* Back Button to return to Sign-in with FontAwesome Icon */}
            <Fab
              color="primary"
              size="small"
              onClick={handleOpenTherapistModal}
              sx={{
                mt: 2,
                backgroundColor: '#388e3c',
                '&:hover': {
                  backgroundColor: '#2C1C18',
                },
                mx: 'auto', // Center the FAB horizontally
                display: 'block',
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </Fab>
          </form>
        </Box>
      </Modal>

      <Modal open={openPatientSignupModal} onClose={handleClosePatientSignupModal}>
        <Box sx={modalStyle}>
          <Typography color='#00695C'  variant="h6" align="center">Patient Sign-up</Typography>
          <form onSubmit={(event) => handleSubmitSignup(event, 'Patient')}>
            <TextField label="Name" name="name" fullWidth required sx={{ marginBottom: 2 }} InputLabelProps={{ style: { color: '#2C1C18' } }} />
            <TextField label="Email" name="email" type='email' onChange={handleEmailChange} fullWidth required  sx={{ marginBottom: 2 }} InputLabelProps={{ style: { color: '#2C1C18' } }} error={!!error && error.includes('email')} helperText={error.includes('email') && error} />
            <TextField label="Phone" value={formData.phone} onChange={handlePhoneChange} name="phone" fullWidth required sx={{ marginBottom: 2 }} InputLabelProps={{ style: { color: '#2C1C18' } }} inputProps={{ maxLength: 12 }} helperText="Phone should be in format 2547XXXXXXXX" />
            {/* Checkbox for accepting terms */}
            <FormControlLabel
                  control={<Checkbox onChange={(e) => setAcceptTerms(e.target.checked)} sx={{ color: '#2C1C18', '&.Mui-checked': { color: '#00695C',}, }} />}
                  label={
                    <>
                      I accept the{' '}
                      <Typography
                        component="span"
                        sx={{ color: '#00695C', cursor: 'pointer', textDecoration: 'underline' }}
                        onClick={handleOpenTermsModal}
                      >
                        terms and conditions
                      </Typography>
                    </>
                  }
                />
            <Button variant="contained" fullWidth type="submit" disabled={loading}>
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign Up'}
            </Button>
          </form>
        </Box>
      </Modal>

      <Modal open={openTherapistSignupModal} onClose={handleCloseTherapistSignupModal}>
        <Box sx={modalStyle}>
          <Typography color='#00695C'  variant="h6" align="center">Therapist Sign-up</Typography>
          <form onSubmit={(event) => handleSubmitSignup(event, 'Therapist')}>
            <TextField label="Name" name="name" fullWidth required sx={{ marginBottom: 2 }} InputLabelProps={{ style: { color: '#2C1C18' } }} />
            <TextField label="Practice" name="practice" fullWidth required sx={{ marginBottom: 2 }} InputLabelProps={{ style: { color: '#2C1C18' } }} />
            <TextField label="Email" name="email" type='email' onChange={handleEmailChange} fullWidth required sx={{ marginBottom: 2 }} InputLabelProps={{ style: { color: '#2C1C18' } }} error={!!error && error.includes('email')} helperText={error.includes('email') ? error : ''} />
            <TextField label="Phone" name="phone" onChange={handlePhoneChange} fullWidth required sx={{ marginBottom: 2 }} InputLabelProps={{ style: { color: '#2C1C18' } }} inputProps={{ maxLength: 12 }} helperText="Phone should be in format 2547XXXXXXXX" />
            {/* Checkbox for accepting terms */}
            <FormControlLabel
                  control={<Checkbox checked={acceptTerms} onChange={(e) => setAcceptTerms(e.target.checked)} sx={{ color: '#2C1C18', '&.Mui-checked': { color: '#00695C',}, }} />}
                  label={
                    <>
                      I accept the{' '}
                      <Typography
                        component="span"
                        sx={{ color: '#00695C', cursor: 'pointer', textDecoration: 'underline' }}
                        onClick={handleOpenTermsModal}
                      >
                        terms and conditions
                      </Typography>
                    </>
                  }
                />
            <Button variant="contained" fullWidth type="submit" disabled={loading}>
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign Up'}
            </Button>
          </form>
        </Box>
      </Modal>

      {/* Terms and Conditions Modal */}
      <Modal open={openTermsModal} onClose={handleCloseTermsModal}>
  <Container
    maxWidth="sm"
    sx={{
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '8px',
      marginTop: '50px',
      maxHeight: '80vh',
      overflowY: 'auto',
    }}
  >
    <Typography variant="h6" sx={{fontWeight:'bold'}}>Terms and Conditions for Using Cope.ke</Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>
      Effective Date: Sept 2024
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Welcome to Cope.ke, a platform offering online therapy services for individuals and organizations. These Terms and Conditions ("Terms") govern your use of our website, mobile app, and services (collectively referred to as the "Platform"). By accessing or using Cope.ke, you agree to these Terms. Please read them carefully before using our services. If you do not agree with these Terms, you should not use our Platform.
    </Typography>

    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>1. Introduction and Acceptance of Terms<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Cope.ke provides access to professional mental health services, including therapy sessions, self-assessment tools, and related mental health content. By using the Platform, you acknowledge that:
      <ul>
        <li>You are at least 18 years old or have obtained parental or guardian consent to use the Platform.</li>
        <li>You are legally able to enter into a contract.</li>
        <li>You accept these Terms and agree to abide by them.</li>
      </ul>
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>2. Privacy Policy<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Your privacy is essential to us. Our Privacy Policy outlines how we collect, store, and protect your data. By using Cope.ke, you agree to the terms of our Privacy Policy. We use industry-standard security measures to protect your data, including encryption and secure communications.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>3. Eligibility for Services<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Cope.ke is designed for users seeking mental health services in the form of therapy sessions, consultations, or self-assessment tools. To use our Platform, you must:
      <ul>
        <li>Be a resident of a country where our services are legally available.</li>
        <li>Provide accurate and up-to-date personal and contact information.</li>
      </ul>
      Our services are not intended for users in immediate crisis or medical emergencies. If you are experiencing a medical or mental health emergency, please contact local emergency services.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>4. Use of the Platform<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      You agree to use Cope.ke only for lawful purposes and in accordance with these Terms. You may not:
      <ul>
        <li>Use the Platform for any fraudulent or illegal activities.</li>
        <li>Violate any applicable local, national, or international law while using the Platform.</li>
        <li>Interfere with the security or functionality of the Platform, including hacking, introducing viruses, or data scraping.</li>
      </ul>
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>5. Therapist Services and Responsibilities<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Cope.ke connects users with licensed and qualified mental health professionals ("Therapists"). All Therapists on the Platform are independent practitioners and not employees of Cope.ke. The responsibility for the quality of therapy services provided rests solely with the Therapist. Cope.ke:
      <ul>
        <li>Does not provide medical advice, diagnosis, or treatment.</li>
        <li>Is not liable for any professional advice or actions taken by a Therapist during your sessions.</li>
      </ul>
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>6. Booking and Payment Terms<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      a. Booking Appointments:<br />
      Users can book therapy sessions through the Platform by selecting a Therapist, choosing an available time slot, and confirming their booking. Booking confirmations will be sent via email, SMS, or other communication channels.
      <br />
      b. Payments:<br />
      Payment is required at the time of booking unless otherwise stated. All payments are processed securely, and prices will be displayed in the local currency or a specified alternative. Refunds may be issued under limited circumstances and at the sole discretion of Cope.ke.
      <br />
      c. Cancellations and Rescheduling:<br />
      You may cancel or reschedule your appointment at least 24 hours in advance without penalty. Late cancellations or no-shows may result in a fee or loss of the session fee.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>7. Self-Assessment Tools<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Cope.ke offers self-assessment tools designed to help users better understand their mental health status. These tools are intended for informational purposes only and are not a substitute for professional mental health evaluation or diagnosis. They should not be relied upon to make critical health decisions.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>8. Data Privacy and Security<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Your personal information and data privacy are critical to us. Cope.ke complies with applicable data protection laws. By using our Platform, you agree to our collection, use, and disclosure of your information as described in our Privacy Policy.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>9. Platform Availability and Changes<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      We strive to ensure that our Platform is available 24/7, but we cannot guarantee uninterrupted access. Cope.ke reserves the right to modify, update, or discontinue any part of the Platform at any time without prior notice.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>10. Third-Party Links and Content<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Our Platform may contain links to third-party websites, resources, or services. These links are provided for your convenience, and we do not endorse or take responsibility for the content or practices of these third parties.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>11. Disclaimer of Warranties<br />
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Cope.ke makes no warranties or guarantees about the accuracy, reliability, or availability of the Platform or the services provided by Therapists. The Platform is provided on an "as-is" and "as-available" basis without any express or implied warranties of any kind.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>12. Limitation of Liability<br />
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      To the maximum extent permitted by law, Cope.ke and its affiliates, officers, directors, and employees are not liable for any indirect, incidental, or consequential damages arising from the use of the Platform or therapy services.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>13. Indemnification<br />
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      You agree to indemnify and hold harmless Cope.ke and its affiliates from any claims, liabilities, damages, losses, or expenses arising out of your use of the Platform or your violation of these Terms.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>14. Governing Law and Dispute Resolution<br />
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      These Terms are governed by the laws of [Insert Country]. Any disputes arising out of or relating to these Terms or your use of Cope.ke shall be resolved through good faith negotiations between the parties, and if necessary, binding arbitration.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>15. Changes to Terms<br />
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      We may modify these Terms from time to time. Any changes will be posted on our website, and you will be notified through email or other communication channels.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>16. Termination of Services<br />
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Cope.ke reserves the right to terminate or suspend your account or access to the Platform for any reason, including breach of these Terms.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>17. Contact Information<br />
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      If you have any questions or concerns regarding these Terms or your use of the Platform, please contact us at:
      <br />
      Email: hello@cope.ke<br />
    </Typography>

    <Button onClick={handleCloseTermsModal} sx={{ mt: 2 }}>
      OK
    </Button>
  </Container>
</Modal>

      {/* Snackbar for Success Messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

// Set PropTypes for the Navbar component
Navbar.propTypes = {
  setPage: PropTypes.func.isRequired,
};

export default Navbar;
