import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  Button,
  Modal,
  CircularProgress,
  TextField,
  Snackbar,
  Alert,
  Chip,
  Avatar,
  Tooltip,
  useMediaQuery,
  useTheme,
  Fab,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  EventAvailable as RescheduleIcon,
  Info as InfoIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { addDays, format } from "date-fns";

const SuperuserAppointments = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [appointments, setAppointments] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [appointmentDate, setAppointmentDate] = useState(format(addDays(new Date(), 1), "yyyy-MM-dd"));
  const [appointmentTime, setAppointmentTime] = useState("09:00");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [providers, setProviders] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  useEffect(() => {
    fetchAppointments();
    fetchProviders();
  }, []);

  const fetchAppointments = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://api.cope.ke/appointments");
      setAppointments(response.data);
      setFilteredAppointments(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch appointments data", severity: "error" });
      setLoading(false);
    }
  };

  const fetchProviders = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/service_providers");
      setProviders(response.data);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch providers data", severity: "error" });
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredAppointments(
      appointments.filter(
        (appointment) =>
          appointment.name.toLowerCase().includes(value) ||
          appointment.user_email.toLowerCase().includes(value)
      )
    );
  };

  const handleRescheduleClick = (event, appointment) => {
    event.stopPropagation();
    setFormData(appointment);
    setOpenAppointmentModal(true);
  };

  const handleDeleteClick = (event, appointment) => {
    event.stopPropagation();
    setSelectedAppointment(appointment);
    setOpenConfirmationModal(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`https://api.cope.ke/manage_appoitments/${selectedAppointment.appointment_id}`);
      fetchAppointments();
      setSnackbar({ open: true, message: "Appointment deleted successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to delete appointment", severity: "error" });
    }
    setOpenConfirmationModal(false);
  };

  const handleAppointmentSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.put(`https://api.cope.ke/manage_appoitments/${formData.appointment_id}`, { 
        provider_id: selectedProvider, 
        appointment_date: appointmentDate,
        appointment_time: appointmentTime,
      });
      fetchAppointments();
      setOpenAppointmentModal(false);
      setSnackbar({ open: true, message: "Appointment rescheduled successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to reschedule appointment", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const formatDate = (dateStr) => {
    return format(new Date(dateStr), "EEE, do MMM yyyy");
  };

  const renderCards = () => (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {filteredAppointments.map((appointment) => (
        <Card key={appointment.appointment_id} sx={{ width: 300, m: 2, cursor: "pointer" }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Avatar>
                <FontAwesomeIcon icon={faUser} size="2x" />
              </Avatar>
              <Box>
                <Tooltip title="Delete Appointment">
                  <IconButton onClick={(e) => handleDeleteClick(e, appointment)} sx={{ color: "red" }}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Reschedule Appointment">
                  <IconButton onClick={(e) => handleRescheduleClick(e, appointment)} sx={{ color: "blue" }}>
                    <RescheduleIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Typography variant="h6">{appointment.name}</Typography>
            <Typography variant="body2">
              <FontAwesomeIcon color="#00695C" icon={faEnvelope} /> {appointment.user_email}
            </Typography>
            <Typography variant="body2">
              Appointment Date: {formatDate(appointment.appointment_date)}
            </Typography>
            <Typography variant="body2">
              Appointment Time: {appointment.appointment_time || "Not Set"}
            </Typography>
            <Typography variant="body2">
              Status: <Chip label={appointment.status} color="primary" />
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography color="#00695C" variant="h4" gutterBottom>Your Appointments</Typography>
        <Tooltip title="How to use">
          <IconButton>
            <InfoIcon color="primary" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search appointments"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <SearchIcon />
            ),
          }}
          sx={{ flexGrow: 1, mr: 2 }}
        />
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredAppointments.length > 0 ? (
        renderCards()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No appointments found.
        </Typography>
      )}

      <Modal open={openAppointmentModal} onClose={() => setOpenAppointmentModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleAppointmentSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom sx={{ color: "teal" }}>Reschedule Appointment</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="appointmentDate"
            label="Appointment Date"
            name="appointmentDate"
            type="date"
            value={appointmentDate}
            onChange={(e) => setAppointmentDate(e.target.value)}
            InputProps={{ inputProps: { min: format(addDays(new Date(), 1), "yyyy-MM-dd") }}}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="appointmentTime"
            label="Appointment Time"
            name="appointmentTime"
            type="time"
            value={appointmentTime}
            onChange={(e) => setAppointmentTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="provider-select-label">Service Provider</InputLabel>
            <Select
              labelId="provider-select-label"
              id="provider-select"
              value={selectedProvider}
              label="Service Provider"
              onChange={(e) => setSelectedProvider(e.target.value)}
            >
              {providers.map((provider) => (
                <MenuItem key={provider.provider_id} value={provider.provider_id}>
                  {provider.company_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openConfirmationModal} onClose={() => setOpenConfirmationModal(false)}>
        <Box sx={{ ...modalStyle, width: 300, textAlign: "center" }}>
          <Typography variant="h6" gutterBottom>Confirm Deletion</Typography>
          <Typography variant="body1" gutterBottom>Are you sure you want to delete this appointment?</Typography>
          <Box display="flex" justifyContent="space-around" mt={2}>
            <IconButton
              sx={{ color: "red", '&:hover': { color: "darkred" } }}
              onClick={() => setOpenConfirmationModal(false)}
            >
              <CancelIcon />
            </IconButton>
            <IconButton
              sx={{ color: "green", '&:hover': { color: "darkgreen" } }}
              onClick={confirmDelete}
            >
              <CheckCircleIcon />
            </IconButton>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Tooltip title="Return to Top">
        <Fab color="primary" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} sx={{ position: "fixed", bottom: 16, right: 16 }}>
          <KeyboardArrowUpIcon />
        </Fab>
      </Tooltip>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserAppointments;
